exports.components = {
  "component---src-layouts-guides-tsx": () => import("./../../../src/layouts/guides.tsx" /* webpackChunkName: "component---src-layouts-guides-tsx" */),
  "component---src-layouts-regulations-layout-tsx": () => import("./../../../src/layouts/RegulationsLayout.tsx" /* webpackChunkName: "component---src-layouts-regulations-layout-tsx" */),
  "component---src-layouts-vendors-tsx": () => import("./../../../src/layouts/vendors.tsx" /* webpackChunkName: "component---src-layouts-vendors-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-quick-guide-index-tsx": () => import("./../../../src/pages/quick-guide/index.tsx" /* webpackChunkName: "component---src-pages-quick-guide-index-tsx" */),
  "component---src-pages-quick-guide-whats-new-tsx": () => import("./../../../src/pages/quick-guide/whats-new.tsx" /* webpackChunkName: "component---src-pages-quick-guide-whats-new-tsx" */)
}

